import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const Header = () => {
  return (
    <React.Fragment>
      <header className="bg-image">
        <div className="bg-container">
          <h1>Rtec Ltd</h1>
          <h2>
            Worry and manage nothing regarding your infrastructure. Leave it us
            <br></br>
            to achieve high Performance, Reliability, Availability and Security
            <br></br>
            with great customer experience.{" "}
          </h2>
          <Link to="/ContactUs">Contact us now</Link>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
